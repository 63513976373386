import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import authModule from './authModule';
import staffModule from '@/store/staffModule';
import loadingModule from './loadingModule';
import notificationModule from './notificationModule';
import customerModule from '@/store/customerModule';
import companyModule from '@/store/companyModule';
import loanModule from '@/store/loanModule';
import bankModule from '@/store/bankModule';
import dashboardModule from '@/store/dashboardModule';
import walletModule from './walletModule';
import transactionsModule from './transactionsModule';
import investmentModule from './investmentModule';
import corporateModule from './corporateModule';

Vue.use(Vuex);

const vueXPersistLocal = new VuexPersistence({
  key: 'vendor-credit-portal',
  storage: window.localStorage,
});

export const store = new Vuex.Store({
  modules: {
    auth: authModule,
    banks: bankModule,
    dashboard: dashboardModule,
    loading: loadingModule,
    notifications: notificationModule,
    staff: staffModule,
    customers: customerModule,
    companies: companyModule,
    loans: loanModule,
    wallet: walletModule,
    transactions: transactionsModule,
    investments: investmentModule,
    corporate: corporateModule,
  },
  plugins: [vueXPersistLocal.plugin],
});
